import React, { useEffect, useState } from 'react';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import FeeType from './FeeType';
import FineType from './FineType';
import StudentSchoolSubject from './StudentSchoolSubject';
import Button from '../../bootstrap/Button';
import LateAttendanceReason from './LateAttendanceReason';
import PeriodType from './PeriodType';
import VehicleType from './VehicleType';
import ComplaintType from './ComplaintType';
import ExtraFineType from './ExtraFineType';
import PaperType from './PaperType';
import SparePartsType from './SparePartsType';
import MediumCreation from './Medium';
import { toasts } from '../../../services/toast.service';
import { getModuleType } from '../../../services/common.service';
import SkillType from './SkillType';
import CasteCreation from './CasteCreation';
import HobbieType from './HobbieType';
import CalendarEventType from './CalendarEventType';
import CourseCreation from './CourseCreation';
import HallCreation from '../../academic/exam/HallCreation';

const Module = () => {

	useEffect(() => {
		getModuleTypeList()
	}, []);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [moduleTypeId, setModuleTypeId] = useState<any>('');
	const [dataSuccess, setDataSuccess] = useState(false);
	const [moduleTypeData, setModuleTypeData] = useState<any>([]);

	function getModuleTypeList() {
		getModuleType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.moduleType;
					if (data != undefined) {
						setModuleTypeData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setModuleTypeData([]);
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	const selectModuleType = (e: any) => {
		setDataSuccess(false);
		let moduleTypeId = e;
		setModuleTypeId(moduleTypeId);
	};

	const view = () => {
		setDataSuccess(true);
		setIsOpenListCard(false);
	}

	return (
		<PageWrapper title='Module'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list' tag='form' noValidate>
						<CardHeader borderSize={1}>
							<CardLabel icon='list' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Module
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-3'>
									<FormGroup id='moduleTypeId' label='Module Type' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Module Type'
											onChange={selectModuleType}
											value={moduleTypeId}
											list={moduleTypeData.map((data: any) => ({
												value: data.moduleTypeId,
												label: data.moduleType,
											}))}
										/>
									</FormGroup>
								</div>
								{moduleTypeId ?
									<div className='col-md-2'>
										<Button
											className='mt-2'
											icon='ArrowDownward'
											color='primary'
											onClick={view}>
											View
										</Button>
									</div> : null
								}
							</div>
						</CardBody>
					</Card>
				</Collapse>
				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>
				{dataSuccess ? <>
					{moduleTypeId?.value == 1 ?
						<FeeType /> : moduleTypeId?.value == 2 ?
							<FineType /> : moduleTypeId?.value == 3 ?
								<StudentSchoolSubject /> : moduleTypeId?.value == 4 ?
									<LateAttendanceReason /> : moduleTypeId?.value == 5 ?
										<PeriodType /> : moduleTypeId?.value == 6 ?
											<VehicleType /> : moduleTypeId?.value == 7 ?
												<ComplaintType /> : moduleTypeId?.value == 8 ?
													<ExtraFineType /> : moduleTypeId?.value == 9 ?
														<PaperType /> : moduleTypeId?.value == 10 ?
															<SparePartsType /> : moduleTypeId?.value == 11 ?
																<MediumCreation /> : moduleTypeId?.value == 12 ?
																	<HallCreation /> : moduleTypeId?.value == 13 ?
																		<SkillType /> : moduleTypeId?.value == 14 ?
																			<CasteCreation /> : moduleTypeId?.value == 15 ?
																			<HobbieType /> : moduleTypeId?.value == 16 ?
																				<CourseCreation /> : moduleTypeId?.value == 17 ?
																					<CalendarEventType /> : null
					}   </> : null
				}

			</Page>
		</PageWrapper>
	);
};

export default Module;
