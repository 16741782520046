import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import AuthContext from '../../../../contexts/authContext';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { getCurrentDateAndTime, convertDateToEpoch } from '../../../../services/common.service';
import { getScheduleDetailsByDate } from '../../../../services/timeTableService';
import { toasts } from '../../../../services/toast.service';
import Card, { CardTitle, CardBody, CardHeader, CardActions, CardLabel, CardSubTitle } from '../../../../components/bootstrap/Card';
import { useFormik } from 'formik';
import { getAttendanceReportByStudentDetailsId, getStudentTotalFeeCollection } from '../../../../services/fee.service';
import Icon from '../../../../components/icon/Icon';
import HumansWebp from '../../../../assets/img/scene2.webp';
import Humans from '../../../../assets/img/scene2.webp';
import Timeline, { TimelineItem } from '../../../../components/extras/Timeline';
import { getStudentActivityLog } from '../../../../services/dashboard.service';
import ClassToper from './ClassToper';
import Announcement from '../../../../components/general/administration/Announcement';
import { checkStudentDueCertificates } from '../../../../services/certificate.service';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Button from '../../../../components/bootstrap/Button';

const StudentDashboardSchedule = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		const upto = getCurrentDateAndTime('date');
		const currentDate = new Date();
		// Subtract 7 days to get the start date
		const startDate = new Date(currentDate);
		startDate.setDate(startDate.getDate() - 7);
		const formattedDate = startDate.toDateString();

		console.log(upto);
		console.log(formattedDate);

		getActivityLogList(
			userAccountId,
			convertDateToEpoch(formattedDate),
			convertDateToEpoch(upto),
		);
		getScheduleDetailsList()
		getStudentTotalFeeCollectionList(userAccountId)
		getAttendanceReport(userAccountId)
	}, []);

	const { themeStatus, darkModeStatus } = useDarkMode();
	const [scheduleData, setScheduleData] = useState<any>([]);
	const [studentTotalFeeCollectionData, setStudentTotalFeeCollectionData] = useState<any>([]);
	const [balanceAmt, setBalanceAmt] = useState<number>(0);
	const [attendanceReport, setAttendanceReport] = useState<any>('');

	const staffScheduleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			date: getCurrentDateAndTime('date'),
		},
		validate: (values) => {
			const errors: {
				date?: string;
			} = {};

			if (!values.date) {
				errors.date = 'Required';
			}
			return errors;
		},

		//validateOnChange: false,
		onSubmit: () => { },
	});

	const [dayOrder, setDayOrder] = useState<any>('');
	const [dayId, setDayId] = useState<any>('');
	const [dataStatus, setDataStatus] = useState<any>(false);
	const [type, setType] = useState('year');

	function getScheduleDetailsList() {
		let scheduleDate = staffScheduleForm.values.date;
		let dateInMilliSec = convertDateToEpoch(scheduleDate);

		getScheduleDetailsByDate(
			userTypeId,
			userAccountId,
			dateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.scheduleDetailsByDate[0].period;
					if (data != undefined) {
						setDataStatus(true);
						setScheduleData(data);
						setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
						setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
						for (let i = 0; i < data.length; i++) {
							data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
						}
					} else {
						toasts('Undefined Data', 'Error');
						setDataStatus(true);
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setScheduleData([]);
					setDataStatus(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setDataStatus(true);
				}
			},
			(error) => {
				toasts(error, 'Error');
				setDataStatus(true);
			},
		);
	}

	function getStudentTotalFeeCollectionList(studentDetailsId: number) {
		getStudentTotalFeeCollection(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentTotalFeeCollection;
					if (data != undefined) {
						setStudentTotalFeeCollectionData(data);
						setBalanceAmt(
							data[0].semFees_netPayable +
							data[0].prevSemFees_netPayable +
							data[0].busFees_netPayable +
							data[0].prevBusFees_netPayable +
							data[0].extraFees_netPayable,
						);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getAttendanceReport(studentDetailsId: number) {
		getAttendanceReportByStudentDetailsId(
			studentDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.attendanceReportByStudentDetailsId[0];
					if (data != undefined) {
						setAttendanceReport({
							series: [data.presentPerentage],
							options: {
								chart: {
									type: 'radialBar',
									offsetY: -20,
									sparkline: {
										enabled: true,
									},
								},
								plotOptions: {
									radialBar: {
										startAngle: -90,
										endAngle: 90,
										track: {
											background: '#e7e7e7',
											strokeWidth: '97%',
											margin: 5, // margin is in pixels
											dropShadow: {
												enabled: true,
												top: 2,
												left: 0,
												color: '#999',
												opacity: 1,
												blur: 2,
											},
										},
										dataLabels: {
											name: {
												show: false,
											},
											value: {
												offsetY: -2,
												fontSize: '22px',
											},
										},
									},
								},
								grid: {
									padding: {
										top: -10,
									},
								},
								fill: {
									type: 'gradient',
									gradient: {
										shade: 'light',
										shadeIntensity: 0.4,
										inverseColors: false,
										opacityFrom: 1,
										opacityTo: 1,
										stops: [0, 50, 53, 91],
									},
								},
								labels: ['Average Results'],
							},
						});
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [activityData, setActivityData] = useState<any>([]);
	const [noDataMsg, setNoDataMsg] = useState<any>('');
	const colors = ['info', 'success', 'warning', 'secondary', 'primary'];

	function getActivityLogList(studentDetailsId: any, fromDateInMilliSec: any, toDateInMilliSec: any) {
		getStudentActivityLog(studentDetailsId, fromDateInMilliSec, toDateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentActivityLog;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							let activities = data[i].activities;
							for (let i = 0; i < activities.length; i++) {
								activities[i].color =
									colors[Math.floor(Math.random() * colors.length)];
							}
						}
						setActivityData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setNoDataMsg(response.data.message)
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	useEffect(() => {
		if (localStorage.getItem('tourModalStarted') !== 'shown') {

			checkStudentDueCertificate()

			setTimeout(() => {
				localStorage.setItem('tourModalStarted', 'shown');
			}, 7000);
		}
		return () => { };
	}, []);

	const [alertMessage, setAlertMessage] = useState<any>('');
	const [certificateData, setCertificateData] = useState<any>([]);
	const [isOpenModal, setIsOpenModal] = useState(false)

	function checkStudentDueCertificate() {
		checkStudentDueCertificates(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.checkStudentDueCertificates;
					if (data != undefined) {
						setCertificateData(data)
						setAlertMessage(response.data.message);
						setIsOpenModal(true)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	return (
		<PageWrapper title='Schedule'>
			<Page container='fluid'>
				<div className='row'>
					<div className="col-12">
						<Announcement />
					</div>
					<div className='col-9'>
						<Card stretch className='border border-light rounded-1' shadow={'md'}>
							<CardHeader borderSize={1}>
								<CardLabel icon='Schedule' iconColor='info' className='col-lg-5'>
									<CardTitle tag='div' className='h5'>
										Schedule
									</CardTitle>
								</CardLabel>
								&nbsp;&nbsp;
								<CardActions className='d-print-none'>
									<span className='text-success fs-5'>
										<b>{dayOrder}</b>
									</span>
								</CardActions>
							</CardHeader>
							<CardBody>
								{dataStatus ? (
									<>
										{scheduleData != '' && dayId > 0 && dayId != 7 ? (
											<div className='row'>
												{scheduleData.map((period: any) => (
													<div
														className='col-4'
														key={period.periodDetailsId}>
														<Card
															stretch
															shadow={'md'}
															className='border border-light'>
															<CardBody>
																<div className='row align-items-center'>
																	<div className='col d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<div
																				className='ratio ratio-1x1'
																				style={{
																					width: 65,
																				}}>
																				<div
																					className={classNames(
																						'rounded-2',
																						'd-flex align-items-center justify-content-center',
																						{
																							'bg-l10-info':
																								!darkModeStatus,
																							'bg-lo25-info':
																								darkModeStatus,
																						},
																					)}>
																					<span className='fw-bold'>
																						<span
																							className='text-info'
																							style={{
																								fontSize:
																									'23px',
																							}}>
																							{
																								period.periodCount
																							}
																						</span>
																						{/* <br />
																					<small className='text-dark' style={{ fontSize: '10px' }}>
																						period
																					</small> */}
																					</span>
																				</div>
																			</div>
																		</div>
																		<div className='flex-grow-1 ms-2 d-flex justify-content-between align-items-center'>
																			<div>
																				<small className='fw-bold mb-0'>
																					{
																						period.paperName
																					}
																				</small>
																				<div className=' text-info fs-6 mb-0'>
																					{
																						period.staffName
																					}
																				</div>
																				<div className='text-info'>
																					<small>
																						<span className='text-black-50 fw-bold'>
																							{
																								period.startTime
																							}
																							-
																							{
																								period.endTime
																							}
																						</span>
																					</small>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</CardBody>
														</Card>
													</div>
												))}
											</div>
										) : scheduleData != '' && (dayId == 0 || dayId == 7) ? (
											<div className='col-12' style={{ height: '61vh' }}>
												<div className='row'>
													<div
														className='col-12'
														style={{ height: '15vh' }}></div>
													<div className='col-12 d-flex justify-content-center'>
														<Icon icon='CalendarToday' size={'10x'} />
													</div>
													<div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>
														Holiday
													</div>
												</div>
											</div>
										) : (
											<div className='row'>
												<div className='col-12 d-flex justify-content-center'>
													<img
														srcSet={HumansWebp}
														src={Humans}
														alt='Humans'
														style={{ height: '50vh' }}
													/>
												</div>
												<div className='fs-4 fw-bold d-flex justify-content-center text-center text-uppercase text-danger'>
													Schedule Not Allocated
												</div>
												<br />
												<br />
												<br />
												<br />
											</div>
										)}
									</>
								) : null}
							</CardBody>
						</Card>
					</div>

					<div className='col-3'>
						<Card stretch className='border border-light rounded-1' shadow={'md'}>
							<CardHeader borderSize={1}>
								<CardLabel
									icon='MonetizationOn'
									iconColor='info'
									className='col-lg-12'>
									<CardTitle tag='div' className='h5'>
										Fee Details
									</CardTitle>
								</CardLabel>
								&nbsp;&nbsp;
							</CardHeader>
							<CardBody>
								{studentTotalFeeCollectionData.map((studentTotalFee: any) => (
									<>
										<div
											className='col-md-12'
											key={studentTotalFee.studentDetailsId}>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-primary bg-l${darkModeStatus ? 'o50' : '10'
													}-primary-hover transition-base rounded-2 mb-4`}
												shadow='sm'>
												<CardBody>
													<div
														className='d-flex align-items-center pb-3 mt-3'
														style={{ height: '22px' }}>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 40 }}>
															<div
																className={classNames(
																	'rounded-5',
																	'd-flex align-items-center justify-content-center',
																	'bg-primary',
																)}>
																<span className='avatar-1 fs-3 text-white fw-bold'>
																	T
																</span>
															</div>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-4 text-primary mb-0'>
																₹
																{studentTotalFee.termFees_grossPayable +
																	studentTotalFee.busFees_grossPayable +
																	studentTotalFee.extraFees_netPayable}
															</div>
															<div className='text-muted'>
																<b> TOTAL</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>

										<div className='col-md-12'>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-success bg-l${darkModeStatus ? 'o50' : '10'
													}-success-hover transition-base rounded-2 mb-4`}
												shadow='sm'>
												<CardBody>
													<div
														className='d-flex align-items-center pb-3 mt-3'
														style={{ height: '22px' }}>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 40 }}>
															<div
																className={classNames(
																	'rounded-5',
																	'd-flex align-items-center justify-content-center',
																	'bg-success',
																)}>
																<span className='avatar-1 fs-3 text-white fw-bold'>
																	P
																</span>
															</div>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-4 text-success mb-0'>
																₹{' '}
																{studentTotalFee.termFees_amountPaid +
																	studentTotalFee.termFees_concession +
																	studentTotalFee.busFees_concession +
																	studentTotalFee.extraFees_concession +
																	studentTotalFee.busFees_amountPaid +
																	studentTotalFee.extraFees_amountPaid}
															</div>
															<div className='text-muted'>
																<b>PAID</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>

										<div className='col-md-12'>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-danger bg-l${darkModeStatus ? 'o50' : '10'
													}-danger-hover transition-base rounded-2 mb-4`}
												shadow='sm'>
												<CardBody>
													<div
														className='d-flex align-items-center pb-3 mt-3'
														style={{ height: '22px' }}>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 40 }}>
															<div
																className={classNames(
																	'rounded-5',
																	'd-flex align-items-center justify-content-center',
																	'bg-danger',
																)}>
																<span className='avatar-1 fs-3 text-white fw-bold'>
																	B
																</span>
															</div>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-4 text-danger mb-0'>
																₹{' '}
																{studentTotalFee.termFees_netPayable +
																	studentTotalFee.busFees_netPayable +
																	studentTotalFee.extraFees_netPayable}
															</div>
															<div className='text-muted'>
																<b> BALANCE</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>

										<div className='col-md-12'>
											<Card
												className={`bg-l${darkModeStatus ? 'o25' : '25'
													}-warning bg-l${darkModeStatus ? 'o50' : '10'
													}-warning-hover transition-base rounded-2 mb-4`}
												shadow='sm'>
												<CardBody>
													<div
														className='d-flex align-items-center pb-3 mt-3'
														style={{ height: '22px' }}>
														<div
															className='ratio ratio-1x1 me-3'
															style={{ width: 40 }}>
															<div
																className={classNames(
																	'rounded-5',
																	'd-flex align-items-center justify-content-center',
																	'bg-warning',
																)}>
																<span className='avatar-1 fs-3 text-white fw-bold'>
																	F
																</span>
															</div>
														</div>
														<div className='flex-grow-1 ms-3'>
															<div className='fw-bold fs-4 text-warning mb-0'>
																₹ {studentTotalFee.totalFineAmount}
															</div>
															<div className='text-muted'>
																<b>FINE</b>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
									</>
								))}
							</CardBody>
						</Card>
					</div>

					<div className='col-6'>
						<Card stretch className='rounded-1 border border-light'>
							<CardHeader borderSize={1}>
								<CardLabel icon='NotificationsActive' iconColor='warning'>
									<CardTitle tag='div' className='h5'>
										Recent Activities
									</CardTitle>
									<CardSubTitle tag='div' className='h6'>
										last 1 week
									</CardSubTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								{activityData != '' ?
									<Timeline>
										{activityData.map((item: any) => (
											<>
												{item.activities.map((i: any) => (
													<TimelineItem
														key={i.activityLogsId}
														label={i.duration}
														color={i.color}>
														{i.activity}
													</TimelineItem>
												))}
											</>
										))}
									</Timeline> : <div className="d-flex justify-content-center text-uppercase fw-bold text-danger">{noDataMsg}</div>
								}
							</CardBody>
						</Card>
					</div>
					<div className='col-6'>
						<ClassToper />
					</div>
				</div>

				<Modal
					setIsOpen={setIsOpenModal}
					isOpen={isOpenModal}
					titleId='deleteBatchMaster'
					isStaticBackdrop
					size='sm'>
					<ModalHeader>
						<ModalTitle id='edit'>Reminder</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className="row g-2">
							<div className="col-12 d-flex justify-content-start">
								<ul>
									{certificateData.map((item: any) => (
										<li><h6><strong>{item.message}</strong></h6></li>
									))}
								</ul>
							</div>
							<div className='col-12'>
								<div className='d-flex gap-2 justify-content-end'>
									<Button color='primary' size={'sm'} onClick={() => setIsOpenModal(false)}>Continue</Button>
								</div>
							</div>
							<div className='col-10'></div>
						</div>
					</ModalBody>
				</Modal>
			</Page>
		</PageWrapper>
	);
};

export default StudentDashboardSchedule;
